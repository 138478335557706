<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created () {
    if (location.href.includes('shareUrl')) {
      const shareUrl = this.getUrlParams('shareUrl')
      location.href = location.origin + '/#' + shareUrl
    }
  },
  methods: {
    getUrlParams (name) {
      // eslint-disable-next-line no-sparse-arrays
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ''])[1].replace(/\+/g, '%20')) || null
    }
  }
}
</script>

<style lang="less">
</style>
