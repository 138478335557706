import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Homes from '../views/Homes.vue'
import Detaile from '../views/Detaile.vue'
import Video from '../views/Video.vue'
import Videodetaile from '../views/Videodetaile.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: '/index',
    name: 'Homes',
    component: Homes
  },
  {
    path: '/detaile',
    name: 'Detaile',
    component: Detaile
  },
  {
    path: '/video',
    name: 'video',
    component: Video
  },
  {
    path: '/videodetaile',
    name: 'videodetaile',
    component: Videodetaile
  }
]

const router = new VueRouter({
  routes
})

export default router
