<template>
  <div>
    <van-nav-bar @click-right="onClickRight">
      <template #left>
        <van-image width="78"
                   height="25"
                   src="https://mpapi.ghtechwx.com/uploads/image/20220308/d1fd7394dad40f8d9a460661447b7715.png" />
      </template>
      <template #right>
        <van-icon name="ellipsis"
                  size="18"
                  color="#e6212a" />
      </template>
    </van-nav-bar>
    <van-swipe :autoplay="5000"
               class="my-swipe">
      <van-swipe-item v-for="(image, index) in adlist"
                      :key="index"
                      @click="onChange(image.id,image.url,image.adtype)">
        <img v-lazy="image.pic" />
        <span>{{image.title}}</span>
      </van-swipe-item>
    </van-swipe>
    <div class="ghtech-feedlist-video">
      <div id="mse"
           style="max-width: 100%; width: 100%; height: 0px; top: 0px; left: 0px;"></div>
    </div>
    <van-tabs swipeable
              animated
              sticky
              v-model="active">
      <van-tab v-for="(item,index) in articlelist"
               :key="index"
               :title="item.cate_name"
               title-style="font-size: 16px;">
        <van-list v-model="item.loading"
                  :finished="item.finished"
                  finished-text="没有更多了"
                  @load="onLoad">
          <van-cell v-for="(items,index) in item.article"
                    :key="index">
            <div class="list_item"
                 @click="detaile(items.id)">
              <div class="cont ">
                <span class="title">{{items.title}}</span>
                <div class="items_list">
                  <div class="items items_nickname">
                    <span class="talt">{{items.author}} </span>
                  </div>
                  <em class="items items_text">{{items.create_time}}</em>
                </div>
              </div>
              <div class="cover"
                   v-lazy:background-image="items.thumb_urls"
                   style="background-size: cover;"></div>
            </div>
          </van-cell>
        </van-list>
      </van-tab>
    </van-tabs>
    <van-popup v-model="show"
               closeable
               close-icon-position="top-right"
               position="top"
               :style="{ height: '100%'}">
      <div>
        <van-nav-bar style="background-color: #e6212a !important;"
                     fixed>
          <template #left>
            <van-image width="78"
                       height="25"
                       src="https://mpapi.ghtechwx.com/uploads/image/20220308/93aed31e038a081a1ecacfc1a6c402f5.png" />
          </template>
        </van-nav-bar>
        <van-swipe :autoplay="5000"
                   class="my-swipe"
                   style="margin-top: 46px;">
          <van-swipe-item v-for="(image, index) in adlist"
                          :key="index"
                          @change="onChange(image.id,image.url,image.adtype)">
            <img v-lazy="image.pic" />
            <span>{{image.title}}</span>
          </van-swipe-item>
        </van-swipe>
        <van-cell style="margin-top: 20px;">
          <van-grid :border="false"
                    :column-num="3"
                    square
                    :gutter="10">
            <van-grid-item v-for="(itemn, index) in navlist"
                           :key="index"
                           :url="itemn.url"
                           style="height:150px ;">
              <van-image :src="itemn.pic"
                         style="margin-top: 5px;" />
              <div style="text-align: center; margin-top: 5px; ">{{itemn.title}}</div>
            </van-grid-item>

          </van-grid>
        </van-cell>
      </div>
    </van-popup>
    <van-cell>
      <div class="van-ellipsis"
           style="text-align: center;">Copyright © {{site.copyright}}</div>
      <div class="van-ellipsis"
           style="text-align: center;"><a :href="site.beianurl">{{site.beian}}</a></div>
    </van-cell>
  </div>
</template>

<script>
import { getSite, getArticlelist, getAd, getArticle, getNavLists, getShare, getClassAd } from '@/api/global'
// import wx from 'weixin-js-sdk'
import Player from 'xgplayer/dist/simple_player'
import { NavBar, Toast, Icon, Swipe, SwipeItem, Tab, Tabs, Card, Cell, CellGroup, List, Image as VanImage, Popup, Grid, GridItem } from 'vant'
export default {
  name: 'Home',
  components: {
    [NavBar.name]: NavBar,
    [Toast.name]: Toast,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Card.name]: Card,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [List.name]: List,
    [VanImage.name]: VanImage,
    [Popup.name]: Popup,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  data () {
    return {
      show: false,
      active: 0,
      page: 1,
      adlist: [],
      articlelist: [],
      site: '',
      navlist: []
    }
  },
  mounted () {
    this.loadgetadlist()
    this.loadgetClassAd()
    this.loadgetSite()
    this.loadgetNavLists()
    this.loadgetArticlelist()
  },
  methods: {
    loadgetadlist () {
      getAd({ type: this.$route.query.type, token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.adlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetNavLists () {
      getNavLists({ token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.navlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetClassAd () {
      getClassAd({ type: this.$route.query.type, token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.Player = new Player({
            id: 'mse',
            url: res.data.data.url,
            volume: 1, // 初始音量
            autoplay: false, // 自动播放
            playsinline: true,
            fitVideoSize: 'auto',
            poster: res.data.data.pic,
            with: '100%',
            fluid: true
          })
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetSite () {
      getSite({ token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.site = res.data.data
          const hashBefore = location.href.split('#')[0]
          const path = location.href.split('#')[1]
          // 自定义链接
          const url = hashBefore.includes('?') ? `${hashBefore}&shareUrl=${path}` : `${hashBefore}?shareUrl=${path}`
          this.getShareInfo(res.data.data.webtitle, url, res.data.data.wxpic, res.data.data.wxdesc)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetArticlelist () {
      getArticlelist({ type: this.$route.query.type, token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.articlelist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onClickRight () {
      this.show = true
    },
    onChange (id, url, adtype) {
      if (adtype === 1) {
        this.$router.push({ path: '/detaile', query: { id: id } })
      } else {
        window.location.href = url
      }
    },
    onLoad () {
      // 异步更新数据
      setTimeout(() => {
        const _this = this
        _this.articlelist[this.active].page++
        getArticle({ token: '913c1e07fbaca35189f3bcac110bc89h', id: _this.articlelist[_this.active].id, page: _this.articlelist[_this.active].page, per: 10 }).then(res => {
          if (res.data.status === 'success') {
            if (res.data.data.page_total >= _this.articlelist[_this.active].page) {
              res.data.data.page_items.forEach(function (item, index) {
                _this.articlelist[_this.active].article.push(item)
              })
              _this.articlelist[_this.active].loading = false
            } else {
              _this.articlelist[_this.active].finished = true
            }
          }
        }).catch(err => {
          console.log('保存失败', err)
        })
      }, 1000)
    },
    detaile (id) {
      this.$router.push({ path: '/detaile', query: { id: id } })
    },
    getShareInfo (title, wxUrl, pic, desc) {
      const wx = window.wx
      const url = location.href.split('#')[0]
      getShare({ url: url, token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          // localStorage.setItem('jsapi_ticket', res.jsapi_ticket)
          // 拿到后端给的这些数据
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.data.appId, // 必填，公众号的唯一标识，填自己的！
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: [
              'updateAppMessageShareData',
              'updateTimelineShareData'
            ]
          })

          wx.ready(function () {
            // 分享到朋友圈
            wx.updateTimelineShareData({
              title: title, // 分享标题
              link: wxUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: pic, // 分享图标
              success: function () {
                // 设置成功
              }
            })
            // 分享给朋友
            wx.updateAppMessageShareData({
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: wxUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: pic, // 分享图标
              success: function () {
                // 设置成功
              }
            })
          })
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    }
  }
}
</script>

<style lang="less">
#mse {
  flex: auto;
}
.ghtech-feedlist-video {
  background-color: #000;
  position: relative;
}
.ll {
  margin-top: 60px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  img {
    width: 100%;
    height: calc((100vw - 40px) / 2.35);
  }
  span {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    height: 28px;
    background: rgba(0, 0, 0, 0.6);
    filter: progid:DXImageTransform.Microsoft.gradient( GradientType = 0,startColorstr = '#80000000',endColorstr = '#80000000');
    display: block;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    overflow: hidden;
    text-align: center;
    font-family: 'Microsoft YaHei';
  }
}
.van-swipe__indicator--active {
  background-color: #e6212a !important;
  opacity: 1;
}
.van-swipe__indicators {
  position: absolute;
  bottom: 35px;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.list_item {
  position: relative;
  display: block;
  padding: 5px 110px 11px 0px;
}
.cover {
  position: absolute;
  top: 7px;
  right: 0px;
  width: 93px;
  height: 74px;
  background-repeat: no-repeat;
  background-position: center;
}
.cont {
  overflow: visible;
  position: relative;
  min-height: 74px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.title {
  color: #000;
  line-height: 24px;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: normal;
}
.items_list {
  line-height: 15px;
  font-size: 0;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-top: 8px;
}
.items_nickname {
  position: relative;
}
.items {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px 0;
  font-size: 12px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.talt {
  color: #576b95;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.items_list em {
  font-style: normal;
}
.van-grid-item__contents {
  border-top: 2px solid #e6212a !important;
  border-bottom: 2px solid #e6212a !important;
}
</style>
