import request from '@/utils/request'

export const getAd = (data) => {
  return request({
    method: 'POST',
    url: '/api/getAd',
    data
  })
}
export const getArticlelist = (data) => {
  return request({
    method: 'POST',
    url: '/api/getArticlelist',
    data
  })
}
export const getSite = (data) => {
  return request({
    method: 'POST',
    url: '/api/getSite',
    data
  })
}
export const getDetaile = (data) => {
  return request({
    method: 'POST',
    url: '/api/getDetaile',
    data
  })
}
export const getShare = (data) => {
  return request({
    method: 'POST',
    url: '/api/getShare',
    data
  })
}
export const getArticle = (data) => {
  return request({
    method: 'POST',
    url: '/api/getArticle',
    data
  })
}
export const getNavLists = (data) => {
  return request({
    method: 'POST',
    url: '/api/getNavLists',
    data
  })
}
export const getVideoList = (data) => {
  return request({
    method: 'POST',
    url: '/api/getVideoList',
    data
  })
}
export const getVideos = (data) => {
  return request({
    method: 'POST',
    url: '/api/getVideos',
    data
  })
}
export const getVideoRecommend = (data) => {
  return request({
    method: 'POST',
    url: '/api/getVideoRecommend',
    data
  })
}
export const getVideoDetaile = (data) => {
  return request({
    method: 'POST',
    url: '/api/getVideoDetaile',
    data
  })
}
export const getClassAd = (data) => {
  return request({
    method: 'POST',
    url: '/api/getClassAd',
    data
  })
}
