<template>
  <div>
    <van-nav-bar @click-left="onClickLeft"
                 @click-right="onClickRight"
                 fixed>
      <template #left>
        <van-icon name="arrow-left"
                  color="#e6212a" />
      </template>
      <template #right>
        <van-icon name="ellipsis"
                  size="18"
                  color="#e6212a" />
      </template>
    </van-nav-bar>
    <div class="ghtech_media_area_primary">
      <div class="ghtech_media_wrp">
        <div class="ghtech_media_title">{{item.title}}</div>
        <div class="ghtech_media_meta_list">
          <div class="ghtech_media_meta ghtech_media_meta_nickname">
            <a href="javascript:void(0);"
               class="wx_tap_link js_wx_tap_highlight weui-wa-hotarea">{{item.author}} </a>
          </div>
          <em class="ghtech_media_meta ghtech_media_meta_text">{{item.create_time}}</em>
        </div>

        <div class="ghtech_media_content"
             style="visibility: visible;"
             v-html="item.contents">
        </div>
        <div class="ghtech_media_tool">
          <div class="weui-flex">
            <div class="weui-flex__item">
              <div class="media_tool_meta tips_global_primary meta_primary">
                <span>阅读</span>
                <span style="padding-left: 12px;">{{item.hits}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <van-popup v-model="show"
               closeable
               close-icon-position="top-right"
               position="top"
               :style="{ height: '100%'}">
      <div>
        <van-nav-bar style="background-color: #e6212a !important;"
                     fixed>
          <template #left>
            <van-image width="78"
                       height="25"
                       src="https://mpapi.ghtechwx.com/uploads/image/20220308/93aed31e038a081a1ecacfc1a6c402f5.png" />
          </template>
        </van-nav-bar>
        <van-swipe :autoplay="3000"
                   class="my-swipe"
                   style="margin-top: 46px;">
          <van-swipe-item v-for="(image, index) in adlist"
                          :key="index"
                          @change="onChange(image.id,image.url,image.adtype)">
            <img v-lazy="image.pic" />
            <span>{{image.title}}</span>
          </van-swipe-item>
        </van-swipe>
        <van-cell style="margin-top: 20px;">
          <van-grid :border="false"
                    :column-num="3"
                    square
                    :gutter="10">
            <van-grid-item v-for="(itemn, index) in navlist"
                           :key="index"
                           :url="itemn.url"
                           style="height:150px ;">
              <van-image :src="itemn.pic"
                         style="margin-top: 5px;" />
              <div style="text-align: center; margin-top: 5px; ">{{itemn.title}}</div>
            </van-grid-item>

          </van-grid>
        </van-cell>
      </div>
    </van-popup>
    <van-cell>
      <div class="van-ellipsis"
           style="text-align: center;">Copyright © {{site.copyright}}</div>
      <div class="van-ellipsis"
           style="text-align: center;"><a :href="site.beianurl">{{site.beian}}</a></div>
    </van-cell>
  </div>
</template>
<script>
import { getDetaile, getNavLists, getAd, getShare, getSite } from '@/api/global'
import { NavBar, Icon, Swipe, SwipeItem, Cell, CellGroup, Image as VanImage, Popup, Grid, GridItem } from 'vant'
export default {
  name: 'Detaile',
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [VanImage.name]: VanImage,
    [Popup.name]: Popup,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  data () {
    return {
      item: '',
      show: false,
      site: {
        wxpic: ''
      },
      adlist: [],
      navlist: []
    }
  },
  mounted () {
    this.loadgetSite()
    this.loadgetadlist()
    this.loadgetNavLists()
    this.loadgetDetaile()
  },
  methods: {
    loadgetadlist () {
      getAd({ token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.adlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetNavLists () {
      getNavLists({ token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.navlist = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetSite () {
      getSite({ token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.site = res.data.data
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    loadgetDetaile () {
      getDetaile({ id: this.$route.query.id, token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          this.item = res.data.data
          const hashBefore = location.href.split('#')[0]
          const path = location.href.split('#')[1]
          // 自定义链接
          const url = hashBefore.includes('?') ? `${hashBefore}&shareUrl=${path}` : `${hashBefore}?shareUrl=${path}`
          this.getShareInfo(res.data.data.title, url, res.data.data.thumb_urls, res.data.data.digest)
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    },
    onClickLeft () {
      history.go(-1)
    },
    onClickRight () {
      this.show = true
    },
    onChange (id, url, adtype) {
      if (adtype === 1) {
        this.$router.push({ path: '/detaile', query: { id: id } })
      } else {
        window.location.href = url
      }
    },
    getShareInfo (title, wxUrl, pic, desc) {
      const wx = window.wx
      const url = location.href.split('#')[0]
      getShare({ url: url, token: '913c1e07fbaca35189f3bcac110bc89h' }).then(res => {
        if (res.data.status === 'success') {
          // localStorage.setItem('jsapi_ticket', res.jsapi_ticket)
          // 拿到后端给的这些数据
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.data.appId, // 必填，公众号的唯一标识，填自己的！
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature, // 必填，签名，见附录1
            jsApiList: [
              'updateAppMessageShareData',
              'updateTimelineShareData'
            ]
          })

          wx.ready(function () {
            // 分享到朋友圈
            wx.updateTimelineShareData({
              title: title, // 分享标题
              link: wxUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: pic, // 分享图标
              success: function () {
                // 设置成功
              }
            })
            // 分享给朋友
            wx.updateAppMessageShareData({
              title: title, // 分享标题
              desc: desc, // 分享描述
              link: wxUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: pic, // 分享图标
              success: function () {
                // 设置成功
              }
            })
          })
        }
      }).catch(err => {
        console.log('保存失败', err)
      })
    }
  }
}
</script>
<style lang="less" >
.ghtech_media_area_primary {
  padding: 20px 16px 9px;
  padding: calc(20px + constant(safe-area-inset-top))
    calc(16px + constant(safe-area-inset-right)) 7px
    calc(16px + constant(safe-area-inset-left));
  padding: calc(20px + env(safe-area-inset-top))
    calc(16px + env(safe-area-inset-right)) 7px
    calc(16px + env(safe-area-inset-left));
  background-color: #fafafa;
}
.ghtech_media_wrp {
  position: relative;
}
.ghtech_media_title {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-size: 16px;
}
.not_in_mm .ghtech_media_meta_list {
  position: relative;
  z-index: 1;
}
.ghtech_media_meta_list {
  margin-bottom: 22px;
  line-height: 20px;
  font-size: 0;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.ghtech_media_meta_nickname {
  position: relative;
}
.ghtech_media_meta {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px 0;
  font-size: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a {
  color: #576b95;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.weui-wa-hotarea_before:before,
.weui-wa-hotarea:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  min-width: 44px;
  min-height: 44px;
  width: 100%;
  height: 100%;
}
.ghtech_media_meta_list .weui-wa-hotarea:after {
  min-height: 100%;
  min-width: 100%;
  padding: 5px 4px;
}
.ghtech_media_meta_list em {
  font-style: normal;
}
.ghtech_media_meta_text {
  color: rgba(0, 0, 0, 0.3);
}
.ghtech_media_meta {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px 0;
  font-size: 15px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.ghtech_media_content {
  overflow: hidden;
  color: #333;
  font-size: 17px;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  text-align: justify;
  position: relative;
  z-index: 0;
}
.ghtech_media_content * {
  max-width: 100% !important;
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  word-wrap: break-word !important;
}
.ghtech_media_content img {
  width: 100% !important;
  height: 100% !important;
}
.ghtech_media_tool {
  font-size: 14px;
  margin-top: 16px;
  line-height: 1.4;
  overflow: visible;
}
.weui-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.ghtech_media_tool .weui-flex__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  min-width: 0;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.weui-flex__item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
}
.ghtech_media_tool .weui-flex__item .meta_primary:last-child {
  margin-right: 0;
}
.ghtech_media_tool .meta_primary {
  margin-right: 12px;
}
.tips_global_primary {
  color: rgba(0, 0, 0, 0.3);
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  img {
    width: 100%;
    height: calc((100vw - 40px) / 2.35);
  }
  span {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    height: 28px;
    background: rgba(0, 0, 0, 0.6);
    filter: progid:DXImageTransform.Microsoft.gradient( GradientType = 0,startColorstr = '#80000000',endColorstr = '#80000000')\9;
    display: block;
    line-height: 25px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    overflow: hidden;
    text-align: center;
    font-family: 'Microsoft YaHei';
  }
}
.van-swipe__indicator--active {
  background-color: #e6212a !important;
  opacity: 1;
}
.van-swipe__indicators {
  position: absolute;
  bottom: 35px;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
</style>
