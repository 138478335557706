import axios from 'axios'
import JSONbig from 'json-bigint'
// 实例一个axios
const request = axios.create({
  baseURL: 'https://mpapi.ghtechwx.com/',
  transformResponse: [
    function (data) {
      try {
        return JSONbig.parse(data)
      } catch (err) {
        console.log('转换失败', err)
        return data
      }
    }
  ]
})

// 请求拦截器
request.interceptors.request.use(
  function (config) {
    const user = JSON.parse(window.localStorage.getItem('user'))
    if (user) {
      config.headers.Authorization = `${user.token}`
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export default request
